<template>
  <div>
    <b-modal id="modal-doctor" size="xl" title="Pilih Dokter" hide-footer>
      <div class="row">
        <div class="col-md-9">
          <Table
            purpose="modal"
            :items="items"
            :fields="fields"
            @chooseDoctor="chosenDoctor"
          />
        </div>
        <div class="col-md-3" style="margin-top:25px">
          <b-table
            striped
            hover
            class="mt-3"
            responsive
            :items="itemCommisionServiceDoctor"
            :fields="fieldDoctor"
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.key === 'actions' ? '20%' : '' }"
              />
            </template>
            <template #cell(actions)="data">
              <div class="d-flex">
                <template>
                  <b-button
                    size="sm"
                    class="btn-danger mr-1"
                    style="padding: 5px !important"
                    @click="btnDeleteService(data.item.id)"
                    ><i class="fas fa-trash fa-md px-0"></i
                  ></b-button>
                </template>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/component/doctors/TableModal.vue";
import module from '@/core/modules/CrudModule.js'

export default {
  components: {
    Table,
  },

  data() {
    return {
      // pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "mobile_phone",
          label: "Nomor Telepon",
          sortable: true,
        },
        {
          key: "polyclinic_name",
          label: "PoliKlinik",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      fieldDoctor: [
        { key: "actions", label: "Aksi" },
        {
          key: "doctor_name",
          label: "Nama",
          sortable: true,
        },
      ],
      // Table Items
      items: [],
      // Form
      form: {
        name: "",
      },
      // other 
      itemCommisionServiceDoctor:[],
    };
  },

  methods: {
    async getDoctorCommision() {
      let response = await module.paginate(`payment-category-x-doctor/get-by-payment-category/${this.$route.params.id}`)
      // Success
      if (response.data != []) {
        // Set Option Select
        this.itemCommisionServiceDoctor = response.data

      }
    },

    async btnDeleteService(id) {

      // Delete Data
      let result = await module.deleteNoFire("payment-category-x-doctor/" + id);
      // If Deleted
      if (result) {
        // this.getDoctorCommision()
        this.$root.$emit('requestAfterDeleteDoctorCommission')
      }

    },

    chosenDoctor(value) {
      console.log(value);
      this.$emit("chosenDoctor", value);
    },

    customDoctorFormOnSubmit() {
      this.$emit("customSubmitedDoctor", this.form.name);
    },
  },

  mounted(){
    this.getDoctorCommision()
    
    this.$root.$on('requestDoctorCommision',()=>{
      this.getDoctorCommision()
    })
  }
};
</script>

<style>
</style>