<template>
  <div class="row">

    <div class="col-12">
      <Card :title="`Komisi Dokter Untuk ${data.name} - Rp ${parseInt(data.price).toLocaleString('id-ID')}`">
        <template v-slot:body>
          <Form
            :form="form"
            :doctors_id="doctors_id"
            :dataItem="data"
            redirect="/payment-categories/list"
          />
        </template>
      </Card>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/masters/payment-categories/DoctorCommisionForm.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        payment_category_id: this.$route.params.id,
        doctors_id: '',
        hospital_income: '',
        doctor_commission: '',
        is_percent:false,
        price: 0
      },          
      // Detail Data
      data: {},      
      // Other
      doctors_id: [],
    }
  },

  methods: {    

    async getData() {
      this.data = await module.get('payment-categories/' + this.$route.params.id)
      this.form.price = parseInt(this.data.price).toLocaleString('id-ID')
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/payment-categories/list')
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Keuangan", route: "" },
      { title: "Biaya Layanan", route: "/payment-categories/list" },
      { title: "Komisi Dokter" },
    ])
    this.getData()
  },

}
</script>